import React from "react";
import { Button, Textarea, Grid } from "@geist-ui/core";
import { getAuth } from "firebase/auth";

const ChatForm = ({ documents }) => {
	const handleClick = async () => {
		try {
			const auth = getAuth();
			const idToken = await auth.currentUser.getIdToken(true);

			const prompt = `
            The current itinerary:
            {
                "trip_name": <String> //Name of Trip
                "entries": ${JSON.stringify(documents)}
            }

            The user message:
            ${inputValue}
            `;

			const response = await fetch("tripUpdate", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + idToken
				},
				body: JSON.stringify({ prompt: prompt })
			});
			const data = await response.text();
			console.log(data);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const [inputValue, setInputValue] = React.useState("");

	const handleInputChange = (e) => setInputValue(e.target.value);

	return (
		<>
			<Grid xs={18}>
				<Textarea
					width="100%"
					value={inputValue}
					onChange={handleInputChange}
					placeholder="How can I help with your trip?"
				/>
			</Grid>
			<Grid xs={5}>
				<Button auto type="secondary" onClick={handleClick} style={{ height: "100%" }}>
					Submit
				</Button>
			</Grid>
		</>
	);
};

export default ChatForm;
