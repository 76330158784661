import React from "react";
import { GeistProvider, CssBaseline, Text } from "@geist-ui/core";
import useEmailLinkSignIn from "./functions/useEmailLinkSignIn"; // Import the custom hook
import ContainerPage from "./components/ContainerPage";
import LoginForm from "./components/LoginForm";
import Trip from "./components/Trip";

function App() {
	const signInStatus = useEmailLinkSignIn();

	const renderContent = () => {
		switch (signInStatus) {
			case "checking":
				return <></>; // Show a message while checking
			case "signedIn":
				return <Trip />; // Show welcome message when signed in
			case "error":
				return <Text p>There was an error signing in.</Text>; // Error message
			default:
				return <LoginForm />; // Show login form for default case
		}
	};

	return <ContainerPage>{renderContent()}</ContainerPage>;
}

export default function MyApp() {
	return (
		<GeistProvider>
			<CssBaseline />
			<App />
		</GeistProvider>
	);
}
