import React, { useState, useEffect } from "react";
import { Text, Grid } from "@geist-ui/core";
import LogoutButton from "./LogoutButton";
import { db } from "../firebase-config";
import { collection, onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import ChatForm from "./TestButton";

const Trip = ({ children }) => {
	const [documents, setDocuments] = useState([]);

	useEffect(() => {
		const uid = getAuth().currentUser.uid;
		const unsubscribe = onSnapshot(collection(db, `users/${uid}/vegas`), (snapshot) => {
			const docs = snapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data()
			}));
			setDocuments(docs);
		});

		// Cleanup listener on unmount
		return () => unsubscribe();
	}, []);

	return (
		<>
			<Grid xs={24}>
				<Text h2>Vegas</Text>
			</Grid>

			{documents.map((doc) => (
				<React.Fragment key={doc.id}>
					<Grid xs={24} sm={12} md={8} lg={6}>
						<Text h4>{doc.date}</Text>
					</Grid>
					<Grid xs={24} sm={12} md={8} lg={6}>
						<Text p>{doc.title}</Text>
					</Grid>
				</React.Fragment>
			))}
			<Grid xs={24}>{children}</Grid>
			<ChatForm documents={documents} />
			<Grid xs={24}>
				<LogoutButton />
			</Grid>
		</>
	);
};

export default Trip;
