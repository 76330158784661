import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Grid, Text, Note, Loading } from "@geist-ui/core";
import ".././firebase-config";
import { getAuth, sendSignInLinkToEmail, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";

const LoginForm = () => {
	const [inputValue, setInputValue] = useState("");
	const [emailLinkSent, setEmailLinkSent] = useState(false); // State to track if email link sent
	const [phoneSubmitted, setPhoneSubmitted] = useState(false); // State to track if phone number submitted
	const [verificationCode, setVerificationCode] = useState(""); // State for verification code
	const [loadingMessage, setLoadingMessage] = useState("");
	const [note, setNote] = useState("");

	const confirmationResultRef = useRef(null); // Use useRef to store the confirmation result

	// eslint-disable-next-line
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	// eslint-disable-next-line
	const phoneRegex = /^(\+?1\s?)?((\(\d{3}\))|\d{3})[\s\-]?\d{3}[\s\-]?\d{4}$/;

	//Needed for Captcha for phone sign in
	useEffect(() => {
		const auth = getAuth();
		if (auth && document.getElementById("recaptcha-container")) {
			console.log("rendering captcha");
			window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
				size: "invisible",
				callback: (response) => {
					// reCAPTCHA solved - allow signInWithPhoneNumber
					console.log(`recaptcha response: ${response}`);
				}
			});
		} else {
			console.log("Firebase Auth not initialized or Recaptcha container not found");
		}
	}, []);

	//On Type
	const handleChange = (e) => {
		setInputValue(e.target.value);
	};

	//Send link if user enters email
	const sendLoginLink = async (email) => {
		setLoadingMessage("Sending login link...");
		setNote("");
		const auth = getAuth();
		const actionCodeSettings = {
			// URL you want to redirect back to. The domain must be listed in the Firebase Console...
			url: "https://tripwire.bot", // Replace with your redirect URL
			handleCodeInApp: true
		};

		try {
			await sendSignInLinkToEmail(auth, email, actionCodeSettings);
			// Save the email locally to complete the sign-in process later
			window.localStorage.setItem("emailForSignIn", email);
			setEmailLinkSent(true);
		} catch (error) {
			console.error("Error sending sign-in link", error);
			reset();
			setNote(`Error sending login link: ${error.message}. Please try again.`);
			// Handle errors here, such as showing a notification
		}
		setLoadingMessage("");
	};

	//Determine if valid, and if email vs phone
	const handleSubmit = async (e) => {
		e.preventDefault();
		setNote("");
		if (validateInput()) {
			const isPhoneNumber = phoneRegex.test(inputValue);
			if (isPhoneNumber) {
				await handlePhoneLogin(inputValue);
			} else {
				await sendLoginLink(inputValue);
			}
		} else {
			setNote("Not a valid email or phone number.");
		}
	};

	//Send verification code text
	const handlePhoneLogin = async (phoneNumber) => {
		setLoadingMessage("Sending verification code...");
		setNote("");
		const auth = getAuth();
		const appVerifier = window.recaptchaVerifier;

		function convertToInternationalFormat(phoneNumber, countryCode = "+1") {
			if (!phoneNumber || phoneNumber.length !== 10 || isNaN(phoneNumber)) {
				throw new Error("Invalid phone number");
			}
			return `${countryCode}${phoneNumber}`;
		}
		phoneNumber = convertToInternationalFormat(phoneNumber);
		try {
			console.log(phoneNumber);
			const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
			confirmationResultRef.current = confirmationResult;
			console.log(confirmationResult);
			setPhoneSubmitted(true);
			// Store confirmationResult in state and prompt user for the verification code
			// After getting the code, use confirmationResult.confirm(code) to sign in
		} catch (error) {
			console.error("Error during phone sign-in", error);
			reset();
			setNote(`Error sending code: ${error.message}`);
			// Handle errors here
		}
		setLoadingMessage("");
	};

	//Verify entered code
	const handleVerifyCode = async () => {
		setLoadingMessage("Verifying...");
		setNote("");
		console.log("handleVerifyCode called");
		const confirmationResult = confirmationResultRef.current;
		console.log("confirmationResult:", confirmationResult);
		console.log("verificationCode:", verificationCode);

		if (confirmationResult && verificationCode) {
			try {
				await confirmationResult.confirm(verificationCode);
				console.log("User signed in");
				reset();
				// User is now signed in
				// Handle post-login logic
			} catch (error) {
				console.error("Error verifying code", error);
				reset();
				setNote("Unable to verify, try again.");
			}
		} else {
			console.log("No verification code or confirmationResult");
		}
		setLoadingMessage("");
	};

	//Reset form (such as on error)
	const reset = () => {
		setPhoneSubmitted(false);
		setEmailLinkSent(false);
		setLoadingMessage("");
	};

	//Valid email or phone?
	const validateInput = () => {
		return emailRegex.test(inputValue) || phoneRegex.test(inputValue);
	};

	//Decide which part of form to render
	const renderForm = () => {
		if (loadingMessage) {
			return (
				<Grid xs={24} justify="flex-start">
					<Loading type="success" style={{ width: 35 + "px" }} />
					<Text p b>
						{loadingMessage}
					</Text>
				</Grid>
			);
		} else if (emailLinkSent) {
			return (
				<Grid xs={24}>
					<Note type="success" label="">
						Click the link in your email to log in.
					</Note>
				</Grid>
			);
		} else if (phoneSubmitted) {
			return (
				<form onSubmit={handleVerifyCode}>
					<Grid xs={24}>
						<Text h3 mb={0}>
							Enter the verification code
						</Text>
					</Grid>
					<Grid xs={24}>
						<Input
							scale={5 / 3}
							clearable
							placeholder="Verification code"
							value={verificationCode}
							onChange={(e) => setVerificationCode(e.target.value)}
						/>
					</Grid>
					<Grid xs={24}>
						<Button type="secondary-light" onClick={handleVerifyCode} htmlType="submit">
							Verify Code
						</Button>
					</Grid>
				</form>
			);
		} else {
			// Render your existing form JSX
			return (
				<form onSubmit={handleSubmit}>
					<Grid xs={24}>
						<Text h3 mb={0}>
							Log in with your email or phone number
						</Text>
					</Grid>
					{note && (
						<Grid xs={24}>
							<Note type="warning" label="" filled>
								{note}
							</Note>
						</Grid>
					)}
					<Grid xs={24}>
						<Input
							scale={5 / 3}
							clearable
							placeholder="Email or phone number"
							value={inputValue}
							onChange={handleChange}
							type={inputValue === "" ? "default" : validateInput() ? "default" : "error"}
						/>
					</Grid>
					<Grid xs={24}>
						<Button type="secondary-light" htmlType="submit">
							Submit
						</Button>
					</Grid>
				</form>
			);
		}
	};

	return (
		<>
			<Grid xs={24}>
				<Text h1>Tripwire</Text>
			</Grid>
			{renderForm()}
			<Grid xs={12}>
				<div id="recaptcha-container"></div>
			</Grid>
		</>
	);
};

export default LoginForm;
