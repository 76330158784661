import React from "react";
import { Button } from "@geist-ui/core";
import { getAuth, signOut } from "firebase/auth";

const LogoutButton = () => {
	const auth = getAuth();

	const handleLogout = () => {
		signOut(auth)
			.then(() => {
				window.location.href = "/";
			})
			.catch((error) => {
				// Handle any errors here
				console.error("Logout error", error);
			});
	};

	return (
		<Button auto type="abort" onClick={handleLogout}>
			Log Out
		</Button>
	);
};

export default LogoutButton;
