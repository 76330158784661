import { useState, useEffect } from "react";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from "firebase/auth";

const useEmailLinkSignIn = () => {
	const [signInStatus, setSignInStatus] = useState("checking"); // 'checking', 'signedIn', 'error', or 'default'

	useEffect(() => {
		const auth = getAuth();

		// Check if user is already signed in
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				// User is signed in
				setSignInStatus("signedIn");
			} else {
				// No user is signed in, then check for sign-in link
				if (isSignInWithEmailLink(auth, window.location.href)) {
					let email = window.localStorage.getItem("emailForSignIn");
					if (!email) {
						setSignInStatus("default");
						//email = window.prompt("Please provide your email for confirmation");
					}
					signInWithEmailLink(auth, email, window.location.href)
						.then(() => {
							window.localStorage.removeItem("emailForSignIn");
							setSignInStatus("signedIn");
						})
						.catch(() => {
							setSignInStatus("error");
						});
				} else {
					setSignInStatus("default");
				}
			}
		});

		// Cleanup the onAuthStateChanged observer
		return () => unsubscribe();
	}, []);

	return signInStatus;
};

export default useEmailLinkSignIn;
