// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBfhq0YeyESr_AKwvrk3WAwxhCxPavlQEA",
	authDomain: "tripwire-ai.firebaseapp.com",
	projectId: "tripwire-ai",
	storageBucket: "tripwire-ai.appspot.com",
	messagingSenderId: "742253886440",
	appId: "1:742253886440:web:038e429349acfb7b61c25d",
	measurementId: "G-8JGWXNV7KM"
};

// Initialize Firebase
// eslint-disable-next-line
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db };
