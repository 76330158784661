import React from "react";
import { Grid } from "@geist-ui/core";

const ContainerPage = ({ children }) => {
	return (
		<Grid.Container gap={2} style={{ padding: "1em" }}>
			{children}
		</Grid.Container>
	);
};

export default ContainerPage;
